import { useEffect } from 'react';
import {
  LoaderFunction,
  Navigate,
  Outlet,
  redirect,
  useLocation,
} from 'react-router-dom';
import { api } from '../../../utils/fetchApi';
import { useUser } from '../../../hooks/useUser';
import { ResolverType } from '../utils/types';
import { USStateId } from '@mosey/utils/constants/us-states';
import {
  clearFinishRedirectUrl,
  getResolverStartDate,
  setFinishRedirectUrl,
} from '../utils/session';
import { useIsQuestionUrl } from '../utils/hooks';

export const loader: LoaderFunction = async ({
  params: { countryId, locationId, resolverType },
}) => {
  let url = '/api/compliance/tasks';
  let regionId: USStateId | undefined;
  const searchParams = new URLSearchParams();

  if (countryId && locationId) {
    regionId = USStateId[locationId.toUpperCase() as keyof typeof USStateId];

    if (regionId) {
      searchParams.set('region_id', regionId);
    } else {
      return redirect(`/home`);
    }
  }

  searchParams.set('is_setup', 'false');

  if (resolverType) {
    switch (resolverType) {
      case ResolverType.Assessment:
        searchParams.set('is_setup', 'true');
        searchParams.set('task_type', ResolverType.Question);
        break;

      case ResolverType.Setup:
        searchParams.set('is_setup', 'true');
        searchParams.set('task_type', ResolverType.Requirement);
        break;

      case ResolverType.Requirement: {
        searchParams.set(
          'completed_start_time',
          getResolverStartDate(ResolverType.Requirement, regionId),
        );
        searchParams.set('task_type', ResolverType.Requirement);
        break;
      }

      case ResolverType.Question:
        searchParams.set(
          'completed_start_time',
          getResolverStartDate(ResolverType.Question, regionId),
        );
        searchParams.set('task_type', ResolverType.Question);
        break;

      case ResolverType.Upcoming:
      case ResolverType.Overdue:
        searchParams.set('date_filter', resolverType);
        break;

      default:
        return redirect('/home');
    }
  }

  if (searchParams.size > 0) {
    url = `${url}?${searchParams.toString()}`;
  }

  return api({ url, method: 'GET' });
};

export const Component = () => {
  const { state } = useLocation();

  useEffect(() => {
    /**
     * When coming to a resolver from JM, store the URL back to JM so the back
     * finish buttons in the resolver know where to take the user.
     */
    if (state?.resolverFinishUrl) {
      setFinishRedirectUrl(state.resolverFinishUrl);
    }

    return () => {
      // If leaving the resolver, clear the URL from storage
      clearFinishRedirectUrl();
    };
    /**
     * We only want this to run once when the component mounts, that is, when
     * you arrive from another route, and only want the cleanup function to run
     * when leaving the resolver, hence the disabled linting rule.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = useUser();
  const isQuestionUrl = useIsQuestionUrl();

  if (!user.legal_entity.use_resolvers && !isQuestionUrl) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
