import { PolicyInstance } from '../views/handbook/types';
import { FormSpec, PersonIdentifierType } from './intake';

export type ValidationError = {
  // Name of the field with the validation error
  loc: string[];
  // Validation message
  msg: string;
  type: string;
};

export type ValidationErrorDetail = ValidationError[];

export enum RequirementCategory {
  Payroll = 'payroll',
  PayrollSetup = 'payroll-setup',
  Registration = 'registration',
  RegistrationMaintenance = 'registration-maintenance',
  HR = 'hr',
  Insurance = 'insurance',
  InsuranceSetup = 'insurance-setup',
  Tax = 'tax',
  TaxSetup = 'tax-setup',
}

export enum RequirementStatus {
  Todo = 'todo',
  Done = 'done',
  Deferred = 'deferred',
  InProgress = 'in-progress',
  Skipped = 'skipped',
}

// This type is a superset of `RequirementStatus` from the API.
export enum RequirementComputedStatus {
  Todo = 'todo',
  Done = 'done',
  InProgress = 'in-progress',
  Overdue = 'overdue',
  Deferred = 'deferred',
  Locked = 'locked',
  Managed = 'managed',
}

export enum RemediationDisplayType {
  Default = 'default',
  Email = 'email',
  Link = 'link',
  Fax = 'fax',
  Mail = 'mail',
  Phone = 'phone',
}

export type DefaultDisplay = {
  type: RemediationDisplayType.Default;
};

export type EmailDisplay = {
  type: RemediationDisplayType.Email;
  email: string;
};

export type PhoneDisplay = {
  type: RemediationDisplayType.Phone;
  phone: string;
};

export type LinkDisplay = {
  type: RemediationDisplayType.Link;
  url: string;
  text: string | null;
};

export type FaxDisplay = {
  type: RemediationDisplayType.Fax;
  number: string;
};

export type MailDisplay = {
  type: RemediationDisplayType.Mail;
  address: string;
};

export type RemediationDisplay =
  | DefaultDisplay
  | EmailDisplay
  | PhoneDisplay
  | LinkDisplay
  | FaxDisplay
  | MailDisplay;

export type Remediation = {
  id?: string;
  data_id: string;
  slug: string;
  title: string;
  description: string;
  optional: boolean;
  order: number;
  display: RemediationDisplay | RemediationDisplay[];
  is_done: boolean;
};

enum AttributeValidation {}

export enum AttributeType {
  Text = 'text',
  Boolean = 'boolean',
  Number = 'number',
  Date = 'date',
}

export type Attribute = {
  id: string;
  description?: string;
  name: string;
  placeholder?: string;
  region_id: number;
  required: boolean;
  type: AttributeType;
  validators: AttributeValidation[];
};

export type RequirementAttribute = {
  attribute: Attribute;
  optional: boolean;
};

export enum ContentType {
  Radio = 'radio',
  DropDown = 'dropdown',
  Input = 'input',
  Resources = 'resources',
  // etc
}

export type StepContentValue = {
  display_name: string;
  value: string;
};

export type StepContent = {
  key: string;
  type: ContentType;
  values: StepContentValue[] | [];
  default_value?: string;
};

export type Step = {
  id: number;
  title: string;
  description?: string;
  contents: StepContent[] | [];
  is_last_step?: boolean;
};

export type GuideSection = {
  section_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any; // SectionForm can take many shapes
  title: string;
  description?: string;
  is_complete: boolean;
  is_last_section: boolean;
};

export type GuideForm = {
  requirement_id: number;
  sections: { [key: number]: GuideSection };
};

export type Guide = {
  requirement_id: string;
  form: GuideForm;
  is_complete: boolean;
};

export type GuideResponse = {
  guide: Guide;
  next_section_id: string;
  next_step: Step;
  skipped_section_ids?: number[];
};

export enum RegionStatus {
  Pending = 'pending',
  Entering = 'entering',
  Maintaining = 'maintaining',
  Exiting = 'exiting',
}

export type Datum = {
  attribute: Attribute;
  value: string;
};

export type Agency = {
  id: string;
  name: string;
  url: string;
};

export type SecurityQuestion = {
  question: string;
  answer: string;
};

export type Login = {
  account_name: string;
  username: string;
  password: string;
  is_active: boolean;
  login_url: string;
  is_supported: boolean;
  security_questions?: SecurityQuestion[];
  is_otp_required: boolean;
  is_email_otp: boolean;
  public_id: string;
};

export type EmailOtp = {
  code: string;
  timestamp: string;
  date: Date;
};

export type LoginWithAgencies = Login & {
  agencies: Agency[];
};

export type AgencyAttribute = {
  description?: string;
  id: number;
  name: string;
  required: boolean;
  value?: string;
};

export type AgencyAccount = {
  id: number;
  name: string;
  attributes: Array<AgencyAttribute>;
  logins: Array<Login>;
};

export type AgencyDetails = Agency & {
  agency_accounts: Array<AgencyAccount>;
};

export type Region = {
  id?: string;
  name: string;
  code: string;
};

export type RegionRef = {
  id: string;
  name: string;
  code: string;
};

export enum RegisteredAgentTransferStatus {
  InProgress = 'in-progress',
  Done = 'done',
}

export type RegisteredAgentTransfer = {
  status: RegisteredAgentTransferStatus;
};

export type RegionNavigator = {
  region: Region;
  entity_type: EntityType;
  tax_class: TaxClass;
  payroll_requirements: string[];
  hr_requirements: string[];
  hr_benefits_requirements: string[];
  entity_tax_requirements: string[];
  sales_tax_requirements: string[];
  registration_requirements: string[];
};

export type LegalEntityRegion = {
  region: Region;
  datums: Datum[];
  employee_count: number;
  status: RegionStatus;
  setup_form_submitted: boolean;
  registered_agent_transfer?: RegisteredAgentTransfer;
  region_navigator: RegionNavigator;
  has_unique_navigator: boolean;
  is_setup_complete: boolean;
  has_employees?: boolean;
  has_physical_location?: boolean;
};

export type LegalEntityRegionRef = {
  region: Region;
  employee_count: number;
  status: RegionStatus;
  setup_form_submitted: boolean;
  has_employees?: boolean;
  has_physical_location?: boolean;
};

export enum EntityType {
  LLC = 'llc',
  CCorp = 'c_corp',
}

export const EntityTypeToName: Record<EntityType, string> = {
  [EntityType.CCorp]: 'C Corporation',
  [EntityType.LLC]: 'LLC',
};

export enum TaxClass {
  Corp = 'corp',
  SCorp = 's_corp',
  Partnership = 'partnership',
  Disregarded = 'disregarded',
  TaxExempt = 'tax_exempt',
}

export enum SubscriptionPlanEnum {
  Free = 'free',
  BasicForStripeAtlas = 'basic',
  StartUp = 'start_up',
  ScaleUp = 'scale_up',

  Basic = 'basic_2024',

  Plus = 'plus_2024',
  Standard = 'standard',
  Advanced = 'advanced',
}

export const ACTIVE_PLANS = [
  SubscriptionPlanEnum.BasicForStripeAtlas,
  SubscriptionPlanEnum.Plus,
  SubscriptionPlanEnum.Standard,
  SubscriptionPlanEnum.Advanced,
];

export type LegalEntity = {
  id: string;
  name: string;
  entity_type: EntityType;
  tax_class: TaxClass;
  incorporation_region?: Region;
  hq_region?: Region;
  regions: LegalEntityRegion[];
  employee_count: number;
  business_profile_submitted: boolean;
  is_subscribed: boolean;
  subscription_plan?: SubscriptionPlanEnum;
  platforms?: Platform[];
  notice_url: string | null;
  use_resolvers: boolean;
  is_handbook_enabled?: boolean;
  is_gutz_enabled: boolean;
  should_catch_basic_gate: boolean;
};

export type LegalEntityRef = {
  id: string;
  name: string;
};

export type Platform = {
  id: string;
  name: string;
};

export type User = {
  id: string;
  email: string;
  first_name?: string;
  last_name?: string;
  is_active: boolean;
  role: string;
  legal_entity: LegalEntity;
  legal_entities: LegalEntityRef[];
  is_platform_user: boolean;
  is_pro_user: boolean;
};

export type SearchResult = {
  requirement_data_id: string;
  term: string;
  search_type: string;
};

export type Address = {
  id?: string;
  address_line_1: string;
  address_line_2?: string;
  city: string;
  state: string;
  country?: string;
  postal_code: string;
};

export type Person = {
  id: string;
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  phone: string;
  birth_date?: string;
  ownership_percentage: number | null;
  ownership_start_date: string | null;
  start_date?: string;
  address?: Address;
  is_officer: boolean;
  is_owner: boolean;
  is_member: boolean;
  is_business?: boolean;
  identifier?: string | null;
  identifier_type?: PersonIdentifierType | null;
};

export type Business = {
  id: string;
  name: string;
  incorporation_region: Region;
  incorporation_date: string;
  physical_address?: Address;
  mailing_address?: Address;
  business_identifier: string;
  ownership_start_date: string;
  ownership_percentage: number;
};

export enum MailSource {
  LegalInc = 'legalinc',
}

export enum MailType {
  RegisteredAgent = 'registered-agent',
  ExternalMail = 'external-mail',
}

export enum MailStatus {
  Unresolved = 'unresolved',
  Resolved = 'resolved',
}

export type Mail = {
  id: string;
  region_code: string;
  source: MailSource;
  source_id: string;
  type: MailType;
  description: string;
  sender?: string;
  received_at: string;
  url: string;
  is_read: boolean;
  assigned_user?: User;
  status: MailStatus;
  summary: MailSummary;
};

export enum MailClassification {
  Penalty = 'penalty',
  Underpayment = 'underpayment',
  TaxRateChange = 'tax_rate_change',
  Other = 'other',
  Fake = 'fake',
}

export type MailSummary = {
  title: string;
  sender: string;
  recipient: string;
  sent_date?: string;
  is_fake: boolean;
  classification: MailClassification;
  explanation: string;
};

export type LegalEntityDocument = {
  id: string;
  document_type: string;
  region_code: string;
  uploaded_on: string;
  document_url?: string;
  workflow_instance_id?: number;
  is_requesting?: boolean;
  uploaded_by?: string;
};

export type Referral = {
  partner_display_name: string;
  discount_percent: number;
};

export type VerifyInvite = {
  legal_entity_name: string;
  ask_for_email: boolean;
};

export type ManagedProvider = {
  name: string;
};

export enum FeeType {
  Fixed = 'fixed',
  Range = 'range',
  Variable = 'variable',
}

export type FeeFixed = {
  type: FeeType.Fixed;
  amount: string;
};

export type FeeRange = {
  type: FeeType.Range;
  min: string;
  max: string;
};

export type FeeVariable = {
  type: FeeType.Variable;
  reason: string;
};

export type Fee = FeeFixed | FeeRange | FeeVariable;

export enum CriteriaType {
  EmployeeCount = 'employee-count',
  PayrollAmount = 'payroll-amount',
  RegionHasEmployees = 'region-has-employees',
  RegionHasPhysicalLocation = 'region-has-physical-location',
  ForeignQualificationDate = 'foreign-qualification-date',
  DoingBusiness = 'doing-business',
  BusinessIncomeTaxNexus = 'business-income-tax-nexus',
  SalesTaxNexus = 'sales-tax-nexus',
  EstimatedTaxLiaiblity = 'estimated-tax-liability',
  SingleRequirement = 'single-requirement',
  EmployeeTerminated = 'employee-terminated',
  CharitableRegistrationNexus = 'charitable-registration-nexus',
  PartnerNexus = 'partner-nexus',
}

export const criteriaTypesToSkipJM: CriteriaType[] = [
  CriteriaType.ForeignQualificationDate,
  CriteriaType.DoingBusiness,
  CriteriaType.BusinessIncomeTaxNexus,
  CriteriaType.SalesTaxNexus,
  CriteriaType.EstimatedTaxLiaiblity,
  CriteriaType.SingleRequirement,
  CriteriaType.EmployeeTerminated,
  CriteriaType.CharitableRegistrationNexus,
  CriteriaType.PartnerNexus,
];

export enum LocationScopeType {
  Entity = 'entity',
  Region = 'region',
  Zip = 'zip',
  City = 'city',
}

export enum ThresholdBoundsType {
  GreaterThan = 'greater-than',
  LessThan = 'less-than',
  Range = 'range',
}

export enum ThresholdDateRangeType {
  Current = 'current',
  PreviousCalendarYear = 'previous',
  Terminated = 'terminated',
}

export type ThresholdBoundsGreaterThan = {
  type: ThresholdBoundsType;
  lower_bound: number;
};

export type ThresholdBoundsLessThan = {
  type: ThresholdBoundsType;
  upper_bound: number;
};

export type ThresholdBoundsRange = {
  type: ThresholdBoundsType;
  lower_bound: number;
  upper_bound: number;
};

export type ThresholdBounds =
  | ThresholdBoundsGreaterThan
  | ThresholdBoundsLessThan
  | ThresholdBoundsRange;

export type LocationScopeEntity = {
  type: LocationScopeType;
};

export type LocationScopeRegion = {
  type: LocationScopeType;
};

export type LocationScopeZip = {
  type: LocationScopeType;
  zip_codes: string[];
  label: string;
};

export type LocationScopeCity = {
  type: LocationScopeType;
  city: string;
};

export type LocationScope =
  | LocationScopeEntity
  | LocationScopeRegion
  | LocationScopeCity
  | LocationScopeZip;

export type CriteriaEmployeeCount = {
  type: CriteriaType;
  scope: LocationScope;
  date_range_type: ThresholdDateRangeType;
  bounds: ThresholdBounds;
};

export type CriteriaPayrollAmount = {
  type: CriteriaType;
  scope: LocationScope;
  bounds: ThresholdBounds;
};

export type CriteriaRegionEmployees = {
  type: CriteriaType;
};

export type CriteriaRegionPhysicalLocation = {
  type: CriteriaType;
};

export type CriteriaForeignQualificationDate = {
  type: CriteriaType;
};

export type CriteriaEstimatedTaxLiability = {
  type: CriteriaType;
  scope: LocationScope;
};

export type Criteria =
  | CriteriaEmployeeCount
  | CriteriaPayrollAmount
  | CriteriaRegionEmployees
  | CriteriaRegionPhysicalLocation
  | CriteriaForeignQualificationDate;

export type RequirementContext = {
  message: string;
  employee_id?: string;
};

export type Resource = {
  name: string;
  url: string;
};

export type Requirement = {
  id?: string;
  data_id: string;
  slug: string;
  region: string[];
  title: string;
  description: string;
  category: RequirementCategory;
  // TODO: Fix this when we start generating types based on the OpenAPI schema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schedule: any;
  optional: boolean;
  order: number;
  resources: Resource[];
  remediations: Remediation[];
  due_date?: string;
  status: RequirementStatus;
  is_virtual: boolean;
  is_managed: boolean;
  managed_provider?: ManagedProvider;
  assigned_user?: User;
  consumes: RequirementAttribute[];
  produces: RequirementAttribute[];
  is_blocked: boolean;
  is_automatable: boolean;
  unblocks?: {
    data_id: string;
    title: string;
  }[];
  domestic_fee?: Fee;
  foreign_fee?: Fee;
  // criteria field to be deprecated
  criteria?: Criteria[];
  has_criteria: boolean;
  is_criteria_satisfied?: boolean;
  created_at: string;
  requirement_context?: RequirementContext;
  tags?: AutomationTypeEnum[];
  eta?: RequirementEta;
  policy_instances?: PolicyInstance[];
};

export type RequirementOverview = {
  id?: string;
  data_id: string;
  region: string[];
  title: string;
  category: RequirementCategory;
  // TODO: Fix this when we start generating types based on the OpenAPI schema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schedule: any;
  due_date?: string;
  status: RequirementStatus;
  is_virtual: boolean;
  is_managed: boolean;
  managed_provider?: ManagedProvider;
  assigned_user?: User;
  is_blocked: boolean;
  requirement_context?: RequirementContext;
  created_at: string;
};

export type RequirementEta = {
  estimation?: EtaRange;
  earliest_date?: string;
  latest_date?: string;
};

export type Integration = {
  id: string;
  name: string;
  slug: string;
  type: string;
};

export type Connection = {
  id: string;
  integration: Integration;
  creating_user: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
  needs_auth: boolean;
  provider: string;
  provider_name: string;
  is_disabled: boolean;
};

export type Invite = {
  id: string;
  email: string;
  is_accepted: string;
};

export enum UpcomingLegislationStageEnum {
  Detected = 'detected',
  Updated = 'updated',
  Finalized = 'finalized',
}

export type UpcomingLegislation = {
  id: string;
  title: string;
  description: string;
  region: Region;
  category: RequirementCategory;
  stage: UpcomingLegislationStageEnum;
  alert_emails_sent: boolean;
  effective_date?: string;
  requirement?: Requirement;
};

export enum AutomationTypeEnum {
  DynamicForm = 'dynamic-form',
  NoticeBoard = 'notices',
  AnnualReport = 'sos-report',
  ForeignQualification = 'foreign-qualification',
  PayrollSetup = 'payroll-setup',
  LocalPayrollSetup = 'local-payroll-setup',
  InitialStatement = 'initial-statement',
  HomeStateOnly = 'home-state-only',
  ForeignStateOnly = 'foreign-state-only',
  Handbook = 'handbook',
}

export enum TimeUnit {
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Year = 'year',
}

export type EtaRange = {
  type: 'range';
  min: number;
  max: number;
  unit: TimeUnit;
};

export type Eta = EtaRange;

export type Automation = {
  forms?: FormSpec[];
  automation_url?: string;
  fee?: Fee;
  eta?: RequirementEta;
};

export interface RequirementPatchUpdate {
  operation: 'assign' | 'unassign' | 'mark-criteria-satisfied';
  user_id?: string;
}
