import { PolicyMarkdownContent } from '@mosey/components/handbook/types';
import Handlebars from 'handlebars';
import {
  AttributeValues,
  Handbook,
  Policy,
  PolicyContent,
  PolicySection,
  PublishableChange,
} from './types';
import { AutomationTypeEnum, Requirement } from '../../types';

export function groupPoliciesBySections(
  policies: Array<Policy>,
): Array<{ section: PolicySection; policies: Policy[] }> {
  const sectionsById = policies.reduce(
    (all, instance) => {
      if (!all[instance.policy_section.id]) {
        all[instance.policy_section.id] = {
          section: instance.policy_section,
          policies: [],
        };
      }
      all[instance.policy_section.id].policies.push(instance);
      return all;
    },
    {} as Record<string, { section: PolicySection; policies: Policy[] }>,
  );
  return Object.values(sectionsById).sort(
    (a, b) => a.section.order - b.section.order,
  );
}

function fillContentItem(item: string, attributeValues: AttributeValues) {
  return Handlebars.compile(item)({ attribute: attributeValues });
}

export function fillPolicyContent(
  policy: Policy,
  attributeValues: AttributeValues,
): PolicyContent {
  return {
    main: policy.content.main.map((item) => ({
      ...item,
      content: fillContentItem(
        (item as PolicyMarkdownContent).content,
        attributeValues,
      ),
    })),
    full_details: policy.content.full_details
      ? fillContentItem(policy.content.full_details, attributeValues)
      : null,
    resources: policy.content.resources,
  };
}

export function getHandbookChanges(draft: Handbook, published?: Handbook) {
  const changes: Array<PublishableChange> = [];
  for (const draftInstance of draft.policy_instances) {
    const publishedInstance = published?.policy_instances.find(
      (pi) => pi.policy_id === draftInstance.policy_id,
    );
    if (!publishedInstance) {
      changes.push({
        type: 'adopted',
        instance: draftInstance,
      });
    } else if (
      publishedInstance.policy_version !== draftInstance.policy_version
    ) {
      changes.push({
        type: 'updated',
        instance: draftInstance,
      });
    }
  }
  return changes;
}

export function isHandbookRequirement(requirement: Requirement) {
  return requirement.tags?.includes(AutomationTypeEnum.Handbook) || false;
}
