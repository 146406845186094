import { FunctionComponent, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  FieldErrors as FieldErrorsT,
  useFormContext,
} from 'react-hook-form';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { USStateName } from '@mosey/utils/constants/us-states';
import { useBatchApi } from '../hooks';
import { Button } from '@mosey/components/buttons/Button';
import {
  BatchApiStatusHandler,
  Section,
  UsStateSelectField,
  RadioField,
} from '../components';
import { Person, Region, SubscriptionPlanEnum } from '../types';
import { useGlobalContext } from '../contexts/globalContext';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { OnboardingBusinessProfileSuccess } from '../views/OnboardingBusinessProfileSuccess';
import { FormError } from '@mosey/components/forms/FormError';
import { withRedirect } from '../utils/routing';
import { useUser } from '../hooks/useUser';
import { getPostOnboardingResolverUrl } from '../utils/redirect';

interface BasicPlanIFormValues {
  hq_state?: string;
  is_hiring_employees?: boolean;
}

type SetupMultipleLocationsProps = {
  locationNames: string[];
  errors?: FieldErrorsT<{ hq_state: string; is_hiring_employees: boolean }>;
  hasFormError?: boolean;
  setIsHqSet: (isSet: boolean) => void;
};

const SetupMultipleLocations: FunctionComponent<
  SetupMultipleLocationsProps
> = ({ locationNames, errors, hasFormError, setIsHqSet }) => {
  const { trigger: triggerValidation, clearErrors } = useFormContext();
  const handleUpdateHqLocation = async () => {
    const isHqSet = await triggerValidation('hq_state');

    if (!isHqSet) {
      return;
    }
    setIsHqSet(true);
    clearErrors();
  };
  return (
    <div className="mt-16 flex w-96 flex-col justify-center space-y-8 font-medium">
      <div className="w-96 text-3xl font-extrabold leading-10 text-gray-700">
        Headquarters
      </div>
      <span className="flex flex-col">
        <UsStateSelectField
          name="hq_state"
          label="Where are you headquartered?"
          description="For distributed teams this is where the highest concentration of founders live or where the CEO lives."
          limitedStateOptions={locationNames}
          reactFormConfig={{ required: 'This is required' }}
          error={errors?.hq_state}
        />
        <Button className="w-24" size="large" onClick={handleUpdateHqLocation}>
          Continue
        </Button>
        {hasFormError && <FormError />}
      </span>
    </div>
  );
};

type SetupIsHiringEmployeesProps = {
  hqRegionCode: string;
  errors?: FieldErrorsT<{ hq_state: string; is_hiring_employees: boolean }>;
  hasFormError?: boolean;
};

const SetupIsHiringEmployees: FunctionComponent<
  SetupIsHiringEmployeesProps
> = ({ hqRegionCode, errors, hasFormError }) => {
  const hqLocation =
    USStateName[hqRegionCode.toUpperCase() as keyof typeof USStateName];
  return (
    <div className="mt-16 flex w-96 flex-col justify-center space-y-8 font-medium">
      <div className="w-96 text-3xl font-extrabold leading-10 text-gray-700">
        Employees in {hqLocation}
      </div>
      <span className="flex flex-col">
        <span className="flex w-96 flex-col">
          <RadioField
            name="is_hiring_employees"
            label={`Do you have employees in ${hqLocation}?`}
            options={[
              { name: 'Yes', value: true },
              { name: 'No, but plan to hire right away', value: true },
              { name: 'No', value: false },
            ]}
            reactFormConfig={{ required: 'This is required' }}
            error={errors?.is_hiring_employees}
          />
        </span>
        <Button className="w-24" type="submit" size="large">
          Continue
        </Button>
        {hasFormError && <FormError />}
      </span>
    </div>
  );
};

type BasicPlanSetupProps = {
  peopleData: Person[];
  regions: Region[];
};

const BasicPlanSetup: FunctionComponent<BasicPlanSetupProps> = ({
  peopleData,
  regions,
}) => {
  const [hasFormError, setFormError] = useState<boolean>(false);
  const { setGlobalState } = useGlobalContext();
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const peopleLocationsUsSet: Set<string> = new Set();
  peopleData
    .filter((p) => p.is_officer || p.is_owner || p.is_member)
    .forEach((person) => {
      const stateNames: string[] = Object.values(USStateName);
      if (person.address?.state && stateNames.includes(person.address.state)) {
        peopleLocationsUsSet.add(person.address.state);
      }
    });
  const peopleLocationsUs: string[] = Array.from(peopleLocationsUsSet);
  const [isHqSet, setIsHqSet] = useState<boolean>(
    peopleLocationsUs.length === 1,
  );

  const formMethods = useForm({
    defaultValues: {
      hq_state:
        peopleLocationsUs.length === 1
          ? regions
              .filter(
                (r) =>
                  r.name.toLowerCase() === peopleLocationsUs[0].toLowerCase(),
              )
              .map((r) => r.code)[0]
          : '',
      is_hiring_employees: undefined,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = formMethods;

  const hqRegionCode = watch('hq_state');

  const onSubmit: SubmitHandler<BasicPlanIFormValues> = (data) => {
    setFormError(false);
    let regionCode: string;
    if (data.hq_state) {
      regionCode = data.hq_state;
    } else {
      setFormError(true);
      return;
    }

    // Make an API call to create LegalEntityRegion and related requirements
    fetchApi({
      url: `/api/onboarding/basic`,
      method: 'POST',
      body: {
        hq_region: regionCode,
        is_hiring_employees: data.is_hiring_employees,
      },
    }).then(({ status, data: responseData }: IApiData) => {
      if (status === ApiStatus.Error) {
        setFormError(true);
        return;
      }

      setGlobalState((oldState) => {
        const newState = { ...oldState };
        newState.user.legal_entity.regions = [
          ...newState.user.legal_entity.regions,
          responseData,
        ];
        newState.user.legal_entity.hq_region = responseData;

        return newState;
      });

      // finish setting up business profile
      fetchApi({
        url: '/api/onboarding/configure_business_profile',
        method: 'POST',
      })
        .then(({ status, data: incorporationRegion }: IApiData) => {
          if (status === ApiStatus.Error) {
            setFormError(true);
            return;
          }

          // update legal entity region if one was created and global state to unlock navigation
          setGlobalState((oldState) => {
            const newState = { ...oldState };
            if (incorporationRegion) {
              newState.user.legal_entity.regions = [
                ...newState.user.legal_entity.regions,
                incorporationRegion,
              ];
            }

            newState.user.legal_entity.business_profile_submitted = true;
            return newState;
          });

          revalidator.revalidate();
        })
        // If the user is gutz enabled, and there is assessment or setup, redirect
        .then(getPostOnboardingResolverUrl)
        .then((resolverRedirectUrl) => {
          navigate(resolverRedirectUrl || '/home', { replace: true });
        });
    });
  };

  return (
    <>
      {peopleLocationsUs.length === 0 ? (
        <>
          {/* All officers and owners have international addresses */}
          <OnboardingBusinessProfileSuccess
            onClick={() =>
              setGlobalState((prevState) => ({
                ...prevState,
                hasCheckedBasicPlanOnboarding: true,
              }))
            }
          />
        </>
      ) : (
        <Section>
          <div className="mt-16 flex flex-col items-center justify-center space-y-8 font-medium">
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col items-center justify-center space-y-8"
              >
                {peopleLocationsUs.length > 1 && !isHqSet && (
                  <SetupMultipleLocations
                    locationNames={peopleLocationsUs}
                    errors={errors}
                    hasFormError={hasFormError}
                    setIsHqSet={setIsHqSet}
                  />
                )}
                {isHqSet && (
                  <SetupIsHiringEmployees
                    hqRegionCode={hqRegionCode}
                    errors={errors}
                    hasFormError={hasFormError}
                  />
                )}
              </form>
            </FormProvider>
          </div>
        </Section>
      )}
    </>
  );
};

export const BasicPlanSetupView: FunctionComponent = () => {
  const batchResponse = useBatchApi([
    {
      url: `/api/legal_entity/people`,
      method: 'GET',
    },
    {
      url: '/api/regions',
      method: 'GET',
    },
  ]);

  const componentPropsFn = ([
    personResponse,
    regions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): BasicPlanSetupProps => {
    return {
      peopleData: personResponse,
      regions,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={BasicPlanSetup}
      componentProps={componentPropsFn}
    />
  );
};

export const ConditionalBasicPlanSetupView = withRedirect(
  BasicPlanSetupView,
  () => {
    const user = useUser();
    return (
      user.legal_entity.subscription_plan ===
      SubscriptionPlanEnum.BasicForStripeAtlas
    );
  },
  '/',
);
