import { FormError } from '@mosey/components/forms/FormError';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Renderer,
  SectionHeading,
  formSpecToRendererConfig,
} from '../../components';
import { useEffect } from 'react';
import {
  ActionFunction,
  LoaderFunction,
  useFetcher,
  useLoaderData,
} from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { Button } from '@mosey/components/buttons/Button';
import { api, apiBatch } from '../../utils/fetchApi';
import NotificationTopicSetting from './NotificationTopicSetting';
import { ErrorIcon } from '@mosey/components/Icons';
import { components, NotificationTopicEnum } from '@mosey/api-types';
import { NotificationTopic } from './types';

type MutationResponse =
  | ({
      isProfileUpdate?: boolean;
      firstName?: string;
      lastName?: string;
      isPreferenceUpdate?: boolean;
      topic_code?: string;
      topic_title?: string;
      enabled?: boolean;
    } & {
      success: boolean;
      errors: Record<string, string> | null;
      isProfileUpdate?: boolean;
      isPreferenceUpdate?: boolean;
    })
  | undefined;

const PROFILE_FORM_SPEC = {
  title: 'Profile',
  sections: [
    {
      form_fields: [
        {
          name: 'firstName',
          label: 'First name',
          required: 'This is required',
          component: {
            type: 'text',
          },
        },
        {
          name: 'lastName',
          label: 'Last name',
          required: 'This is required',
          component: {
            type: 'text',
          },
        },
      ],
    },
  ],
};

type FormValues = {
  firstName?: string;
  lastName?: string;
};

export const action: ActionFunction = async ({
  request,
}): Promise<MutationResponse> => {
  const data = await request.json();
  const { firstName, lastName, topic_code, topic_title, enabled } = data;
  const isProfileUpdate = firstName || lastName;
  const isPreferenceUpdate = topic_code && enabled !== undefined;

  try {
    if (isProfileUpdate) {
      await api({
        url: '/api/users/me',
        method: 'PUT',
        body: {
          first_name: firstName,
          last_name: lastName,
        },
      });
    }

    if (isPreferenceUpdate) {
      await api({
        url: '/api/notifications/user/preferences',
        method: 'POST',
        body: {
          preferences: [
            {
              topic: topic_code,
              enabled,
            },
          ],
        },
      });
    }
  } catch (error) {
    if (isProfileUpdate) {
      return {
        success: false,
        errors: {
          profile: 'Something went wrong, please try again.',
        },
      };
    } else if (isPreferenceUpdate) {
      return {
        success: false,
        errors: {
          preference: `Error saving preference for ${topic_title}. Please try again.`,
        },
      };
    }
  }

  return {
    success: true,
    isPreferenceUpdate,
    isProfileUpdate,
    errors: null,
  };
};

export const loader: LoaderFunction = async () => {
  return apiBatch({
    notificationTopics: {
      url: '/api/notifications/topics',
      method: 'GET',
    },
    notificationUserPreferences: {
      url: '/api/notifications/user/preferences',
      method: 'GET',
    },
  });
};

export const Component = () => {
  const user = useUser();

  const { notificationTopics, notificationUserPreferences } =
    useLoaderData() as {
      notificationTopics: NotificationTopic;
      notificationUserPreferences: components['schemas']['NotificationUserPreferencesPublic'][];
    };

  const formMethods = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      firstName: user.first_name || '',
      lastName: user.last_name || '',
    },
  });

  const {
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors, isDirty, isValid },
  } = formMethods;

  const fetcher = useFetcher<MutationResponse>();
  const onSubmit = async (formData: FormValues) => {
    clearErrors();

    fetcher.submit(
      { ...formData },
      { method: 'PUT', encType: 'application/json' },
    );
  };
  const onCancel = () => {
    reset();
  };

  useEffect(() => {
    if (
      fetcher.data?.success &&
      fetcher.data?.isProfileUpdate &&
      fetcher.state === 'idle'
    ) {
      reset({
        firstName: user.first_name,
        lastName: user.last_name,
      });
    }
  }, [fetcher.data, fetcher.state, user.first_name, user.last_name, reset]);

  return (
    <div className="flex flex-col">
      <div className="p-16">
        <SectionHeading className="flex-1 pb-4" text="Preferences" />

        <div className="flex flex-col py-9">
          <section className="lg:max-w-xl">
            <h2 className="flex-1 mb-4 text-xl font-semibold tracking-tight text-gray-900">
              Profile
            </h2>
            <FormProvider {...formMethods}>
              <fetcher.Form id="update-user" onSubmit={handleSubmit(onSubmit)}>
                <Renderer
                  config={formSpecToRendererConfig(PROFILE_FORM_SPEC)}
                  errors={errors}
                />
                {fetcher.data?.errors?.profile && (
                  <FormError
                    errorMessage={fetcher.data?.errors?.profile}
                    margin="mb-9"
                  />
                )}
                {fetcher.data?.success &&
                  fetcher.data?.isProfileUpdate &&
                  !isDirty && (
                    <div className="mb-9 w-full rounded border border-green-200 bg-green-100 px-4 py-2 text-green-700">
                      Your profile information has been updated!
                    </div>
                  )}
              </fetcher.Form>
            </FormProvider>
            {isDirty && isValid && (
              <div className="flex mb-8 space-x-2">
                <div className="w-24">
                  <Button
                    type="button"
                    isFullWidth
                    variant="secondary"
                    onClick={onCancel}
                    disabled={fetcher.state !== 'idle'}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="w-36">
                  <Button
                    type="submit"
                    form="update-user"
                    isFullWidth
                    disabled={fetcher.state !== 'idle'}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            )}
          </section>
          <hr className="mb-9" />
          <section className="xl:max-w-4xl">
            <h2 className="flex-1 mb-4 text-xl font-semibold tracking-tight text-gray-900">
              Email Notifications
            </h2>
            {fetcher.data?.errors?.preference && (
              <div className="flex mb-2 h-16 w-full rounded-sm border border-rose-200 bg-rose-50 px-4 py-2 text-rose-900 items-center">
                <ErrorIcon className="flex size-6 ml-1 mr-2" />
                {fetcher.data?.errors?.preference}
              </div>
            )}
            {notificationTopics.map((topic) => {
              const topic_code = topic.code.toString();
              const preferenceForTopic = notificationUserPreferences.find(
                (pref) => topic_code === pref.topic,
              );
              // if the user has no preference for this topic, default to enabled
              const enabled = preferenceForTopic?.enabled ?? true;
              return (
                topic.configurable && (
                  <NotificationTopicSetting
                    key={topic_code}
                    title={topic.title}
                    description={topic.description}
                    // TODO: fix this type by fixing autogenerated NotificationTopic type
                    code={topic_code as NotificationTopicEnum}
                    enabled={enabled}
                  />
                )
              );
            })}
          </section>
        </div>
      </div>
    </div>
  );
};
