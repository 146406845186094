import { Requirement } from '../../types';
import { PolicyInstance } from '../../views/handbook/types';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { CopyablePolicyPreview } from './CopyablePolicyPreview';
import { useUser } from '../../hooks/useUser';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { USStateName } from '@mosey/utils/constants/us-states';

type HandbookRequirementCompleteProps = {
  requirement: Requirement;
};

export const HandbookRequirementComplete = ({
  requirement,
}: HandbookRequirementCompleteProps) => {
  const {
    legal_entity: { is_handbook_enabled: isHandbookEnabled },
  } = useUser();

  if (isHandbookEnabled) {
    return (
      <div className="h-full">
        <h2 className="text-2xl font-semibold tracking-tight text-zinc-700">
          Policies
        </h2>
        <ul className="mt-4 w-full max-w-3xl">
          {requirement.policy_instances?.map(
            (policyInstance: PolicyInstance) => (
              <TaskSummaryCard
                key={policyInstance.id}
                as="li"
                variant="card"
                to={`/handbook/section/${policyInstance.policy_section.id}/policies#${policyInstance.slug}`}
                status="done"
                title={policyInstance.title}
                region={
                  USStateName[requirement.region[0] as keyof typeof USStateName]
                }
                reason={{
                  title: 'State Compliance',
                  type: 'new-requirement',
                }}
                target="_blank"
              />
            ),
          )}
        </ul>
        <p className="mt-10">
          This requirement is managed by your{' '}
          <TextLink to="/handbook">employee handbook</TextLink>.
        </p>
      </div>
    );
  }

  return (
    <div className="h-full">
      <h2 className="text-2xl font-semibold tracking-tight text-zinc-700">
        Policies
      </h2>
      <p className="mt-2">
        You generated the policies below. Copy these policies to a location
        accessible by your employees.
      </p>
      <p className="mt-2">
        Want to automatically share them with your employees?{' '}
        <TextLink to="https://mosey.com/handbook-beta/" target="_blank">
          Learn about the employee handbook beta.
        </TextLink>
      </p>
      <div className="mt-8 space-y-8">
        {requirement.policy_instances?.map((policyInstance: PolicyInstance) => (
          <CopyablePolicyPreview
            key={policyInstance.id}
            policyInstance={policyInstance}
          />
        ))}
      </div>
    </div>
  );
};
