import { FunctionComponent, useState, MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router';
import { clsx } from 'clsx';
import {
  LoadingIcon,
  MoreIcon,
  SparkleIcon,
  CheckIcon,
  ClipboardCheckIcon,
  StopwatchIcon,
  LockClosedFilledIcon,
  RefreshIcon,
  RobotIcon,
} from '@mosey/components/Icons';
import { MenuItem, DropdownMenu } from '@mosey/components/menus/DropdownMenu';
import { icon as iconStyle } from '@mosey/components/menus/styles';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { Button } from '@mosey/components/buttons/Button';
import {
  AssignSelect,
  BackButton,
  Section,
  SectionHeading,
  TextTruncateThree,
  RequirementProgress,
  BatchApiStatusHandler,
  RequirementFee,
} from '../components';
import { useBatchApi } from '../hooks';
import {
  isDone as requirementIsDone,
  isManagedByMosey as requirementIsManagedByMosey,
  statusColorMapping,
} from '../utils/requirement';
import {
  formatDateFromString,
  isoDate,
  requirementComputedStatus,
} from '../utils/format';
import { toUTCMidnight } from '@mosey/utils/dates';
import { fetchApi } from '../utils/fetchApi';
import * as types from '../types';
import { CriteriaQuestions } from '../components/CriteriaQuestions';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { APP_BASE_URL } from '../settings/config';
import { useRequirementFee } from '../hooks/useRequirementFee';
import { ApiStatus, IFetchApi } from '../utils/types';
import { NoticeUrlDisplay } from './Notices_NEW';
import { useUser } from '../hooks/useUser';
import { Pill, PillProps } from '@mosey/components/badges/Pill';
import {
  ResolverType,
  TaskQuestionRef,
  TaskRef,
  TaskStatus,
} from './tasks-framework/utils/types';
import { USStateId } from '@mosey/utils/constants/us-states';

const LEGAL_ENTITY_REQUIREMENT_ID_LENGTH = 16;

type RequirementProps = {
  requirements: types.Requirement[];
  users: types.User[];
  instanceDueDate?: string;
  toggleReload: () => void;
};

/*
   Choose a requirement based on the instance date
   - If there are no requirement instances, throw an exception
   - If there is an instance matching the instanceDueDate, return it
   - If no date is passed, return the last reified instance,
    defaulting to the last record if no reified instance
 */
const chooseSelectedRequirement = (
  requirements: types.Requirement[],
  instanceDueDate?: string,
) => {
  // There's a possibility that no requirements is returned from API
  if (!requirements.length) {
    throw new Error('No requirements passed');
  }

  // If instance date is passed and a matching requirement exist, return it
  if (instanceDueDate) {
    const selected = requirements.find(
      (requirement) => requirement.due_date === instanceDueDate,
    );
    if (selected) {
      return selected;
    }
  }

  // If there are any reified (non-virtual) instances, return the last one
  const reifiedInstances = requirements.filter(
    (requirement) => !requirement.is_virtual,
  );
  if (reifiedInstances.length) {
    return reifiedInstances.pop() as types.Requirement;
  }

  // Default to the last requirement instance, which would be virtual one
  const lastRequirement = requirements[requirements.length - 1];
  return lastRequirement;
};

const statusIconMapping: Record<
  types.RequirementComputedStatus,
  PillProps['Icon']
> = {
  [types.RequirementComputedStatus.Todo]: ClipboardCheckIcon,
  [types.RequirementComputedStatus.Done]: CheckIcon,
  [types.RequirementComputedStatus.Deferred]: StopwatchIcon,
  [types.RequirementComputedStatus.Locked]: LockClosedFilledIcon,
  [types.RequirementComputedStatus.Overdue]: StopwatchIcon,
  [types.RequirementComputedStatus.InProgress]: RefreshIcon,
  [types.RequirementComputedStatus.Managed]: RobotIcon,
};

export const Requirement: FunctionComponent<RequirementProps> = ({
  requirements,
  users,
  instanceDueDate,
  toggleReload,
}) => {
  const { legal_entity: legalEntity } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isImported, setIsImported] = useState<boolean>(false);
  const [questionTasks, setQuestionTasks] = useState<TaskQuestionRef[]>([]);
  const currentUrl = useLocation();
  const callbackUrl = `${APP_BASE_URL}${currentUrl.pathname}${currentUrl.search}`;

  const requirement = chooseSelectedRequirement(requirements, instanceDueDate);
  const fee = useRequirementFee(requirement);

  const [showCriteriaQuestions, setShowCriteriaQuestions] = useState(
    requirement.criteria &&
      !legalEntity.is_gutz_enabled &&
      !requirement.is_criteria_satisfied &&
      requirement.status !== types.RequirementStatus.Deferred &&
      !requirement.is_blocked,
  );

  const locationId = requirement.region[0];
  const legalEntityRegion = legalEntity.regions.find(
    (region) => region.region.code === locationId,
  );

  useEffect(() => {
    const taskSearchParams = new URLSearchParams();
    const regionId =
      USStateId[locationId.toUpperCase() as keyof typeof USStateId];
    taskSearchParams.set('region_id', regionId);
    taskSearchParams.set('task_type', ResolverType.Question);
    taskSearchParams.set('statuses', TaskStatus.todo);
    taskSearchParams.set('requirement_id', requirement.data_id);
    fetchApi({
      url: `/api/compliance/tasks?${taskSearchParams.toString()}`,
      method: 'GET',
    }).then(({ status, data, error }) => {
      const tasks: TaskQuestionRef[] = [];
      if (status !== ApiStatus.Loading && !error) {
        data.forEach((task: TaskRef) => {
          const questionTask = task.source as TaskQuestionRef;
          tasks.push(questionTask);
        });
        setQuestionTasks(tasks);
      }
    });
  }, [requirement.data_id, locationId]);

  const handleImportSuccess = () => {
    setIsImported(true);
    toggleReload();
  };

  const deferHandler = async (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading(true);

    const status =
      requirement.status === types.RequirementStatus.Deferred
        ? types.RequirementStatus.Todo
        : types.RequirementStatus.Deferred;

    await fetchApi({
      url: `/api/requirements/${requirement.id}`,
      method: 'PUT',
      body: { status },
    });

    // Add a timeout here so it never flashes on the screen
    setTimeout(() => {
      setIsLoading(false);
      setShowCriteriaQuestions(false);
      toggleReload();
    }, 400);
  };

  const handleAssignmentChange = (userId: string | null) => {
    setIsLoading(true);

    let body: types.RequirementPatchUpdate;
    if (userId) {
      body = {
        operation: 'assign',
        // eslint-disable-next-line
        user_id: userId,
      };
    } else {
      body = {
        operation: 'unassign',
      };
    }

    fetchApi({
      url: `/api/requirements/${requirement.id}`,
      method: 'PATCH',
      body,
    });

    // Add a timeout here so it never flashes on the screen
    setTimeout(() => setIsLoading(false), 400);
  };

  const isAttributeProducer = requirement.produces.length > 0;
  const isManaged = requirement.is_managed;
  const isManagedByMosey = requirementIsManagedByMosey(requirement);
  const isDone = requirementIsDone(requirement);
  const isImportable =
    !isImporting &&
    !isDone &&
    !isManaged &&
    !isManagedByMosey &&
    isAttributeProducer;
  const isLocked = requirement.is_blocked;
  const isAutomatable =
    requirement.is_automatable &&
    !isManaged &&
    !isDone &&
    !isLocked &&
    requirement.status !== types.RequirementStatus.Deferred &&
    !requirement.is_virtual;

  return (
    <Section isFullscreen>
      <div className="mb-6 ml-8 mt-8">
        <BackButton />
      </div>
      <SectionHeading
        className="flex items-center px-8 pb-4"
        text={requirement.title}
        rightActionElement={
          <div className="flex items-center">
            {isLoading ? (
              <div className="flex items-center">
                <span className="mr-2 text-sm text-gray-600">Saving</span>
                <LoadingIcon className="size-6 cursor-pointer text-gray-400" />
              </div>
            ) : (
              <DropdownMenu
                isIconButton
                ariaButtonText="Change requirement status"
                buttonContent={
                  <MoreIcon className={iconStyle} aria-hidden="true" />
                }
              >
                <MenuItem as="button" onClick={deferHandler}>
                  {requirement.status === types.RequirementStatus.Deferred
                    ? 'Mark as Todo'
                    : 'Defer'}
                </MenuItem>
              </DropdownMenu>
            )}
          </div>
        }
      />
      <div className="no-scrollbar h-full flex-1 overflow-y-auto bg-white pb-6">
        <div className="grid pt-8 2xl:grid-cols-12">
          <div className="col-span-12 2xl:flex">
            <div className="flex items-start lg:col-span-11 2xl:col-span-7">
              <div className="px-9">
                <div className="mt-2 text-sm text-zinc-700 xl:grid xl:grid-cols-12">
                  <div className="xl:col-span-8">
                    <TextTruncateThree text={requirement.description} />
                    <div className="mt-2">
                      <ul className="list-disc">
                        {requirement.resources &&
                          requirement.resources.map((resource) => (
                            <li
                              key={`${resource.url}`}
                              className="flex items-center text-sm"
                            >
                              <TextLink to={resource.url} target="_blank">
                                {resource.name}
                              </TextLink>
                            </li>
                          ))}
                      </ul>
                    </div>

                    {legalEntity.notice_url &&
                      requirement.tags?.includes(
                        types.AutomationTypeEnum.NoticeBoard,
                      ) &&
                      legalEntityRegion &&
                      isManagedByMosey &&
                      isDone && (
                        <div className="mt-4 space-y-4">
                          <p>
                            Copy the link below to an internal wiki or document
                            that is readibly accessible to all remote employees
                            and notify them of where to find it. Updates will
                            happen automatically for all regions you add.
                          </p>

                          <NoticeUrlDisplay region={legalEntityRegion.region} />
                        </div>
                      )}
                    {isAutomatable && (
                      <>
                        {fee && (
                          <div className="my-9">
                            <RequirementFee fee={fee} />
                          </div>
                        )}
                        <div className="mt-6 flex whitespace-nowrap">
                          <Button
                            as={Link}
                            // need to use APP_BASE_URL to match hosts to prevent logout in CallbackInterstitial
                            to={`/automation/${requirement.id}?callback_url=${callbackUrl}`}
                          >
                            Automate with Mosey
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  {isImportable && (
                    <div className="mt-4 flex items-start xl:col-span-4 xl:mt-0 xl:justify-end xl:pl-4">
                      <div className="rounded border bg-sage-200 p-4 text-base leading-6 text-rose-600 xl:max-w-xs">
                        <div className="flex items-center justify-between">
                          <SparkleIcon className="mr-2 mt-2 size-5 self-start rounded-3xl text-rose-700" />
                          <div className="flex-1">
                            <div className="text-base font-bold leading-7 text-zinc-700">
                              Already did this?
                            </div>
                            <div className="text-sm text-zinc-700">
                              Import your information to mark this requirement
                              as done and set up tracking.
                            </div>
                          </div>
                        </div>
                        <Button
                          type="button"
                          className="ml-6 mt-2"
                          variant="secondary"
                          onClick={() => setIsImporting(true)}
                        >
                          Import
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-y py-3 text-sm">
          <div className="flex flex-wrap items-center gap-y-4 px-8">
            {requirement.due_date && (
              <>
                <span className="font-semibold text-zinc-800">Due Date</span>
                <span className="ml-2 mr-4 inline-block border-r pr-4">
                  {formatDateFromString(requirement.due_date)}
                </span>
              </>
            )}
            <span className="font-semibold text-zinc-800">Status</span>
            <span className="ml-2 mr-4 inline-block">
              <Pill
                Icon={statusIconMapping[requirementComputedStatus(requirement)]}
                variant={
                  statusColorMapping[requirementComputedStatus(requirement)]
                }
                size="small"
              >
                {requirementComputedStatus(requirement)}
              </Pill>
            </span>
            {!requirement.is_virtual && (
              <>
                <span className="border-l pl-4 font-semibold text-zinc-800">
                  Assigned to
                </span>
                <span className="ml-2">
                  <LoadingIcon
                    className={clsx(
                      'mr-1 inline size-5 cursor-pointer text-gray-400',
                      {
                        hidden: !isLoading,
                      },
                    )}
                  />
                  <AssignSelect
                    users={users}
                    assignedTo={requirement.assigned_user}
                    onChange={handleAssignmentChange}
                    alignRight
                  />
                </span>
              </>
            )}
          </div>
        </div>
        {showCriteriaQuestions && legalEntityRegion && !isImporting ? (
          <CriteriaQuestions
            legalEntityRegion={legalEntityRegion}
            requirementData={requirement}
            onComplete={() => {
              setShowCriteriaQuestions(false);
              toggleReload();
            }}
            onDefer={deferHandler}
          />
        ) : (
          <div className="mt-7 px-8">
            <RequirementProgress
              requirement={requirement}
              isImported={isImported}
              isImporting={isImporting}
              isManaged={isManaged}
              isManagedByMosey={isManagedByMosey}
              onImportSuccess={handleImportSuccess}
              onImportCancel={() => setIsImporting(false)}
              onRequirementChange={toggleReload}
              questionTasks={questionTasks}
            />
          </div>
        )}
      </div>
    </Section>
  );
};

export const RequirementView: FunctionComponent = () => {
  const { requirementId, dueDate: dueDateFromParam } =
    useParams<Record<string, string | undefined>>();
  const [toggleReload, setToggleReload] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const dueDate = searchParams.get('dueDate') || dueDateFromParam;

  const apiCalls: IFetchApi[] = [
    {
      url: '/api/users',
      method: 'GET',
    },
  ];
  if (
    requirementId &&
    requirementId.length === LEGAL_ENTITY_REQUIREMENT_ID_LENGTH
  ) {
    apiCalls.push({
      url: `/api/requirements/${requirementId}`,
      method: 'POST',
    });
  } else {
    const todayUTC = toUTCMidnight(new Date());
    let startDate = todayUTC;

    const employeeId = searchParams.get('employeeId');

    if (dueDate) {
      const dueDateUTC = toUTCMidnight(new Date(dueDate));

      if (dueDateUTC < todayUTC) startDate = dueDateUTC;
    }

    // Create a new Date object so we don't mutate _todayUTC_
    const endDate = toUTCMidnight(new Date());
    endDate.setDate(endDate.getDate() + 90);

    const body = {
      /* eslint-disable camelcase */
      start_date: isoDate(startDate),
      end_date: isoDate(endDate),
      requirement_id: requirementId,
      ...(employeeId && { employee_id: employeeId }),
      /* eslint-enable camelcase */
    };

    apiCalls.push({
      url: '/api/requirements',
      method: 'POST',
      body,
    });
  }

  const batchResponse = useBatchApi(
    apiCalls,
    // Re-render if the requirement ID or due date change or reloading
    [requirementId, dueDate, toggleReload],
  );

  const componentPropsFn = ([
    teamResponse,
    requirementsResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): RequirementProps => {
    return {
      requirements:
        typeof requirementsResponse.id === 'string'
          ? [requirementsResponse]
          : requirementsResponse.data,
      users: teamResponse,
      instanceDueDate: dueDate,
      toggleReload: () => setToggleReload((prev) => !prev),
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={Requirement}
      componentProps={componentPropsFn}
    />
  );
};
