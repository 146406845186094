import { getStateSealImage } from '../../../utils/seals';
import { useResolverLocation } from '../utils/hooks';

export const MutedStateSeal = () => {
  const location = useResolverLocation();

  if (!location) {
    return null;
  }

  return (
    <div className="relative mx-auto size-full overflow-hidden rounded-full bg-white">
      <div
        className="absolute inset-0 overflow-visible bg-teal-100 mix-blend-color"
        aria-hidden
      />
      <img
        className="absolute inset-0 opacity-50 grayscale"
        src={getStateSealImage(location.code)}
        alt={`${location.name} state seal`}
      />
      <div
        className="absolute inset-0 bg-teal-400 opacity-50 mix-blend-color"
        aria-hidden
      />
    </div>
  );
};
