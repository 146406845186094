import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  useTasks,
  useResolverUrl,
  useIsNewTask,
  useResolverLocation,
  useNewTaskVisibility,
} from '../utils/hooks';
import { ResolverType, TaskRef, TaskRouteParams } from '../utils/types';
import { getFinishRedirectUrl } from '../utils/session';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { isTaskOverdue } from '../utils';
import { BackButton } from '../../../components';

interface ResolverSidebarItemProps {
  task: TaskRef;
}

const ResolverSidebarItem = ({ task }: ResolverSidebarItemProps) => {
  const { id, status, title } = task;
  const ref = useRef<HTMLLIElement>(null);
  const { taskId } = useParams<TaskRouteParams>();
  const urlPrefix = useResolverUrl();
  const isCurrentTask = taskId === id;
  const isNew = useIsNewTask(id);
  const isOverdue = isTaskOverdue(task);
  const isVisible = useNewTaskVisibility(id);

  useEffect(() => {
    if (isCurrentTask && ref.current && isVisible) {
      ref.current.scrollIntoView({ block: 'nearest' });
    }
  }, [isCurrentTask, isVisible]);

  return (
    <TaskSummaryCard
      ref={ref}
      as="li"
      variant="nav"
      to={`${urlPrefix}/tasks/${id}`}
      status={isNew ? 'new' : isOverdue ? 'overdue' : status}
      title={title}
      isVisible={isVisible}
    />
  );
};

export const ResolverSidebar = () => {
  const { resolverType } = useParams();
  const tasks = useTasks();
  const resolverLocation = useResolverLocation();
  const backUrl = getFinishRedirectUrl();

  if (tasks.length <= 1) {
    return null;
  }

  return (
    <aside className="my-8 w-72 shrink-0 overflow-y-auto pl-8 pr-[2px] @6xl/task:w-96">
      <nav
        className="flex flex-col gap-y-1"
        aria-live="polite"
        aria-relevant="additions"
      >
        {backUrl && (
          <div className="mb-2">
            <BackButton to={backUrl} />
          </div>
        )}

        <h2 className="text-lg font-medium leading-7 text-zinc-800 @6xl/task:text-base">
          {resolverType === ResolverType.Assessment ||
          resolverType === ResolverType.Question
            ? 'Questions'
            : resolverType === ResolverType.Setup
              ? 'Setup Tasks'
              : 'Compliance Tasks'}
        </h2>

        <p className="text-sm leading-5 text-zinc-600">
          {resolverType === ResolverType.Assessment ||
          resolverType === ResolverType.Question
            ? 'Setup tasks and additional questions will be generated based on your responses'
            : resolverType === ResolverType.Setup
              ? 'These tasks are based on payroll data and your responses to questions'
              : `These tasks have approaching deadlines based on your ${[resolverLocation?.name, 'setup'].join(' ')}`}
        </p>

        <ul className="mt-3 grow">
          {tasks.map((task) => (
            <ResolverSidebarItem key={task.id} task={task} />
          ))}
        </ul>
      </nav>
    </aside>
  );
};
