import { ElementType, ReactNode } from 'react';
import { clsx } from 'clsx';
import { Link, LinkProps } from 'react-router-dom';
import { StatusIcon } from './StatusIcon';
import { TaskCardStatus } from './types';
import { ChevronRightIcon } from '@heroicons/react/outline';

interface TaskCollectionCardProps {
  as?: ElementType;
  size?: 'medium' | 'large';
  title: ReactNode;
  status: TaskCardStatus;
  to: LinkProps['to'];
}

export const TaskCollectionCard = ({
  as: Container = 'div',
  title,
  size = 'large',
  status,
  to,
}: TaskCollectionCardProps) => {
  return (
    <Container className="group/task-collection-card-container">
      <Link
        to={to}
        className={clsx(
          'hover:border-teal-300 hover:bg-teal-200 block rounded-full border border-gray-100 bg-white shadow-sm outline-none hover:shadow focus-visible:ring-2 focus-visible:ring-rose-700',
          {
            'py-1.5 pl-1.5 pr-3': size === 'medium',
            'px-3 py-[10px]': size === 'large',
          },
        )}
        aria-label={`${title}${status === 'done' ? ' (Complete)' : ''}`}
      >
        <div className="flex w-full gap-3">
          <StatusIcon status={status} size="large" />
          <div className="flex w-full flex-1 items-center gap-0.5 self-center overflow-hidden">
            <p className="flex-1 truncate font-medium">{title}</p>
            <ChevronRightIcon className="size-4 text-zinc-600" />
          </div>
        </div>
      </Link>
    </Container>
  );
};
