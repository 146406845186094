import { clsx } from 'clsx';
import { FunctionComponent } from 'react';
import { Button } from '@mosey/components/buttons/Button';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

type QuestionTasksBannerProps = {
  regionCode: string;
  taskId?: string;
  show: boolean;
  isNested?: boolean;
  isSetup?: boolean;
};

export const QuestionTasksBanner: FunctionComponent<
  QuestionTasksBannerProps
> = ({ regionCode, taskId, show, isNested = false, isSetup = false }) => {
  if (!show) {
    return <></>;
  }
  return (
    <div
      className={clsx('', {
        'flex px-6 pt-6': !isNested,
      })}
    >
      <div
        className={clsx(
          'flex w-full rounded-sm border border-solid border-teal-400 bg-teal-50 px-4 py-3 text-teal-900',
          {
            'flex-col': isNested,
            'items-center': !isNested,
          },
        )}
      >
        <div
          className={clsx('flex items-center gap-x-2', {
            'mb-2': isNested,
          })}
        >
          <InformationCircleIcon className="size-6 self-center stroke-1" />
          {isNested && (
            <div className="text-base font-medium">
              Additonal information required
            </div>
          )}
        </div>
        <div
          className={clsx('text-base font-normal', {
            'mb-6': isNested,
            'ml-2 mr-2': !isNested,
          })}
        >
          Please answer a few questions to get the most up-to-date compliance
          tasks.
        </div>
        <div
          className={clsx('w-28', {
            '': isNested,
            'ml-auto mr-12': !isNested,
          })}
        >
          <Button
            type="button"
            variant="secondary"
            as={Link}
            state={{ resolverFinishUrl: location.pathname }}
            to={`/locations/usa/${regionCode.toLowerCase()}/resolver/${isSetup ? 'assessment' : 'question'}${taskId ? `/tasks/${taskId}` : ''}`}
          >
            Answer Questions
          </Button>
        </div>
      </div>
    </div>
  );
};
