import { FunctionComponent, Suspense, useState } from 'react';
import { Await, useAsyncValue, useRouteLoaderData } from 'react-router-dom';
import {
  QuickFilters,
  RequirementsTable,
  BatchApiStatusHandler,
} from '../components';
import { QuestionTasksBanner } from '../components/QuestionTasksBanner';
import { useBatchApi } from '../hooks';
import { LegalEntityRegion, RequirementOverview } from '../types';
import {
  FilterRequirementsBy,
  filterRequirements,
  getCountForFilter,
} from '../utils/filter';
import { computedRequirementCounts } from '../utils/stats';
import { isoDate } from '../utils/format';
import { TaskRef } from './tasks-framework/utils/types';
import { Loading } from './Loading';

type LocationDetailRecurringProps = {
  legalEntityRegion: LegalEntityRegion;
  data: RequirementOverview[];
};

export const LocationDetailRecurring: FunctionComponent<
  LocationDetailRecurringProps
> = ({ legalEntityRegion, data }) => {
  const questionTasks = useAsyncValue() as TaskRef[];
  const requirements = data.filter((r) => r.schedule.category !== 'one-time');
  const computedReqCounts = computedRequirementCounts(requirements);
  const [requirementsForTable, setRequirementsForTable] =
    useState<RequirementOverview[]>(requirements);
  const [statusFilter, setFilter] = useState<FilterRequirementsBy>(
    FilterRequirementsBy.All,
  );

  const handleStatusFilterClick = (reqFilter: FilterRequirementsBy): void => {
    setFilter(reqFilter);
    setRequirementsForTable(filterRequirements(requirements, reqFilter));
  };

  const allowedFilters = [
    FilterRequirementsBy.All,
    FilterRequirementsBy.Upcoming,
    FilterRequirementsBy.Done,
    FilterRequirementsBy.Overdue,
    FilterRequirementsBy.Locked,
  ];

  return (
    <>
      <QuestionTasksBanner
        regionCode={legalEntityRegion.region.code}
        show={legalEntityRegion.is_setup_complete && questionTasks.length > 0}
      />
      <div className="px-6 pb-6 pt-4">
        <div className="flex items-center pb-4">
          <h2 className="flex-1 font-bold leading-8 text-zinc-800">
            Requirements
          </h2>
          <QuickFilters
            items={allowedFilters.map((f) => {
              return {
                label: f,
                count: getCountForFilter(computedReqCounts, f),
                isSelected: statusFilter === f,
              };
            })}
            onClick={handleStatusFilterClick}
          />
        </div>
        <div className="min-h-full bg-white">
          <RequirementsTable requirements={requirementsForTable} />
        </div>
      </div>
    </>
  );
};

type LocationDetailRecurringViewProps = {
  legalEntityRegion: LegalEntityRegion;
};

export const LocationDetailRecurringView: FunctionComponent<
  LocationDetailRecurringViewProps
> = ({ legalEntityRegion }) => {
  const { questionTasks } = useRouteLoaderData('question-tasks-jm') as {
    questionTasks: Promise<TaskRef[]>;
  };
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 60);
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 90);
  const regionCodes = [legalEntityRegion.region.code.toLowerCase()];

  const batchResponse = useBatchApi(
    [
      {
        url: '/api/requirements',
        method: 'POST',
        body: {
          start_date: isoDate(startDate), // eslint-disable-line
          end_date: isoDate(endDate), // eslint-disable-line
          region_codes: regionCodes, // eslint-disable-line
          include_virtual: true, // eslint-disable-line
        },
      },
    ],
    [],
  );

  const componentPropsFn = ([
    requirementsResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): LocationDetailRecurringProps => {
    return {
      legalEntityRegion,
      data: requirementsResponse.data as RequirementOverview[],
    };
  };

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={questionTasks}>
        <BatchApiStatusHandler
          batchResponse={batchResponse}
          componentProps={componentPropsFn}
          component={LocationDetailRecurring}
        />
      </Await>
    </Suspense>
  );
};
