import { TaskStatusPill } from '@mosey/components/badges/TaskStatusPill';
import { getStateSealImage } from '../../../utils/seals';
import { useIsOverdue, useTask, useTaskLocation } from '../utils/hooks';
import { TaskStatus, TaskType } from '../utils/types';
import { formatDateFromString } from '../../../utils/format';

export const TaskHeader = () => {
  const { title, status, source } = useTask();
  const taskLocation = useTaskLocation();
  const isOverdue = useIsOverdue();
  const isTodo = status === TaskStatus.todo;
  const dueDate =
    source.type === TaskType.requirement && isTodo && source.due_date
      ? formatDateFromString(source.due_date, {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      : null;

  return (
    <header className="space-y-4">
      <div className="flex items-center">
        {taskLocation && (
          <div className="flex items-center gap-x-2">
            <img
              src={getStateSealImage(taskLocation.code)}
              className="size-6 shrink-0"
              alt={`${taskLocation.name} state seal`}
            />

            <h2 className="text-lg font-medium text-zinc-700">
              {taskLocation.name}
            </h2>
          </div>
        )}

        <div className="ml-auto flex items-center gap-x-6">
          {dueDate && (
            <div className="text-sm leading-6 text-zinc-700">
              Due <time dateTime={dueDate}>{dueDate}</time>
            </div>
          )}

          <TaskStatusPill status={isOverdue ? 'overdue' : status} />
        </div>
      </div>

      <h1 className="grow text-4xl font-bold leading-10 tracking-tight">
        {title}
      </h1>
    </header>
  );
};
