import {
  Link,
  LoaderFunction,
  Navigate,
  useLoaderData,
  useParams,
} from 'react-router-dom';
import {
  MailOpenIcon,
  AnnotationIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import { USStateId } from '@mosey/utils/constants/us-states';
import { CheckIcon } from '@heroicons/react/solid';
import { Button } from '@mosey/components/buttons/Button';
import {
  useTodoTasks,
  useResolverUrl,
  useResolverLocation,
} from '../utils/hooks';
import successCloudImage from '../../../assets/success-cloud.svg';
import smallCloudImage from '../../../assets/small-cloud.svg';
import confetti from '../../../assets/tasks-framework/task-resolver-interstitial-confetti.svg';
import { ResolverType, ResolverRouteParams } from '../utils/types';
import { ResolverSidebar } from './Sidebar';
import { useUser } from '../../../hooks/useUser';
import { FullHeightWrapper, Interstitial } from '../common/Interstitial';
import { NextButton } from '../common/NextButton';
import { getFinishRedirectUrl } from '../utils/session';
import { api } from '../../../utils/fetchApi';
import { MutedStateSeal } from '../common/MutedStateSeal';

const stateSetupCompleteBullets = [
  {
    title: 'Weekly Summary',
    description:
      'You will receive a weekly summary email that includes any upcoming tasks',
    Icon: MailOpenIcon,
  },
  {
    title: 'Threshold Notifications',
    description:
      'You will be notified when you hit thresholds such as employee counts or payroll amounts that require further action',
    Icon: AnnotationIcon,
  },
  {
    title: '24/7 Monitoring',
    description:
      'Ongoing monitoring of upcoming laws and changes in requirements ensure that you will always be compliant',
    Icon: ShieldCheckIcon,
  },
];

export const loader: LoaderFunction = async ({
  params: { countryId, locationId, resolverType },
}) => {
  if (resolverType !== ResolverType.Assessment) {
    return false;
  }

  let regionId: USStateId | undefined;
  const searchParams = new URLSearchParams();

  if (countryId && locationId) {
    regionId = USStateId[locationId.toUpperCase() as keyof typeof USStateId];

    if (regionId) {
      searchParams.set('region_id', regionId);
    }
  }

  searchParams.set('is_setup', 'true');
  searchParams.set('task_type', ResolverType.Requirement);

  const response = await api({
    url: `/api/compliance/tasks?${searchParams.toString()}`,
    method: 'GET',
  });
  const setupTasks = await response.json();

  return setupTasks.length > 0;
};

const StateSetupComplete = () => {
  const location = useResolverLocation()!;

  return (
    <FullHeightWrapper>
      <Interstitial
        percentComplete={100}
        title={
          <>
            You’re all set up in{' '}
            <span className="text-teal-800">{location.name}</span>!
          </>
        }
        description="You’ve activated monitoring and alerting for state and local compliance."
        rightHandContent={
          <div
            className="bg-no-repeat py-8 sm:w-[512px] sm:bg-[center_top_90px] sm:pb-0 sm:pt-[135px]"
            style={{ backgroundImage: `url(${confetti})` }}
          >
            <div className="mx-auto size-[320px]">
              <MutedStateSeal />
            </div>
          </div>
        }
        actions={<NextButton to="/home">Finish</NextButton>}
      >
        <ul className="max-w-xl space-y-10 text-zinc-700">
          {stateSetupCompleteBullets.map(({ title, description, Icon }) => (
            <li key={title} className="flex gap-x-3">
              <Icon
                className="mt-1 size-6 shrink-0 text-teal-700"
                aria-hidden
              />
              <div>
                <p className="text-2xl font-semibold text-teal-900">{title}</p>
                <p>{description}</p>
              </div>
            </li>
          ))}
        </ul>
      </Interstitial>
    </FullHeightWrapper>
  );
};

const StateAssessmentComplete = () => {
  const location = useResolverLocation()!;
  const {
    legal_entity: { use_resolvers: useResolvers },
  } = useUser();
  const hasSetupResolverTasks = useLoaderData() as boolean;

  if (!location) {
    return <Navigate to="/home" replace />;
  }

  const setupUrl = useResolvers
    ? `/locations/usa/${location.code}/resolver/setup`
    : `/locations/usa/${location.code}/setup`;

  return (
    <>
      <div className="mb-4 max-w-xl space-y-6 text-center">
        <div className="mx-auto size-40">
          <MutedStateSeal />
        </div>

        <div className="space-y-1">
          <h1 className="text-4xl font-bold tracking-tight">
            You’re done answering questions!
          </h1>
          <p>
            {hasSetupResolverTasks
              ? 'Your answers have generated setup tasks tailored to your situation.'
              : 'There’s no action required based on your answers.'}
          </p>
        </div>
      </div>

      <Button
        size="xlarge"
        as={Link}
        to={hasSetupResolverTasks ? setupUrl : '/home'}
      >
        Continue
      </Button>
    </>
  );
};

const GenericComplete = () => {
  const finishRedirectUrl = getFinishRedirectUrl();

  return (
    <>
      <div className="relative">
        <img className="w-96" src={successCloudImage} alt="" />

        <div className="absolute left-1/2 top-1/2 size-36 -translate-x-1/2 -translate-y-1/2 rounded-full bg-lime-500 text-white">
          <CheckIcon strokeWidth={3} aria-hidden="true" />
        </div>

        <img
          className="absolute right-20 top-24 w-16 -translate-y-2 translate-x-6"
          src={smallCloudImage}
          alt=""
        />

        <img
          className="absolute bottom-24 left-20 w-16 -translate-x-2 translate-y-4"
          src={smallCloudImage}
          alt=""
        />
      </div>

      <div className="max-w-xl space-y-6 text-center">
        <h1 className="text-5xl font-black">Great Job!</h1>
        <p className="text-lg font-medium">
          All outstanding tasks have been completed.
        </p>
      </div>

      <Button size="xlarge" as={Link} to={finishRedirectUrl ?? '/home'}>
        Finish
      </Button>
    </>
  );
};

export const Component = () => {
  const { locationId, resolverType } = useParams<ResolverRouteParams>();
  const todoTasks = useTodoTasks();
  const resolverUrl = useResolverUrl();

  if (todoTasks.length > 0) {
    return <Navigate to={resolverUrl || '/home'} replace />;
  }

  if (resolverType === ResolverType.Setup) {
    return <StateSetupComplete />;
  }

  return (
    <div className="flex h-full max-w-screen-2xl @container/task">
      <ResolverSidebar />

      <section className="mb-24 flex size-full flex-col items-center justify-center gap-y-6 self-center overflow-y-auto">
        {locationId ? <StateAssessmentComplete /> : <GenericComplete />}
      </section>
    </div>
  );
};
