import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronDownIcon } from '@mosey/components/Icons';
import { PolicyContentType } from '@mosey/components/handbook/types';
import { PolicyContentRenderer } from '@mosey/components/handbook/PolicyContentRenderer';

type PolicyFullDetailsProps = {
  fullDetails: string;
};

export const PolicyFullDetails = ({ fullDetails }: PolicyFullDetailsProps) => {
  return (
    <Disclosure as="div" className="flex flex-col border-gray-200">
      <DisclosureButton className="group flex w-full cursor-pointer py-4 text-sm font-semibold transition text-amber-900">
        <h3 className="text-sm">Full policy details</h3>
        <ChevronDownIcon className="size-5 transition-all group-data-[open]:-rotate-180" />
      </DisclosureButton>
      <DisclosurePanel className="mt-2 pb-4 text-xs">
        <PolicyContentRenderer
          content={{
            type: PolicyContentType.Markdown,
            content: fullDetails,
          }}
        />
      </DisclosurePanel>
    </Disclosure>
  );
};
