import Markdown from 'marked-react';
import { PolicyMarkdownContent } from './types';

type PolicyMarkdownProps = {
  content: PolicyMarkdownContent;
};

export const PolicyMarkdown = ({ content }: PolicyMarkdownProps) => {
  return (
    <div className="handbook-markdown flex flex-col gap-4">
      <Markdown
        renderer={{
          link(href, text) {
            if (href.startsWith('$')) {
              return (
                <abbr key={href} title={text as string}>
                  {href.substring(1)}
                </abbr>
              );
            } else {
              return (
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={href}
                >
                  {text}
                </a>
              );
            }
          },
        }}
        value={content.content}
      />
    </div>
  );
};
