import { LinkProps } from 'react-router-dom';
import { Interstitial } from './tasks-framework/common/Interstitial';
import { NextButton } from './tasks-framework/common/NextButton';
import { AssessmentGraphic } from './tasks-framework/resolver/AssessmentGraphic';

interface LocationDetailViewAssessmentInterstitialProps {
  resolverUrl: LinkProps['to'];
}

export const LocationDetailViewAssessmentInterstitial = ({
  resolverUrl,
}: LocationDetailViewAssessmentInterstitialProps) => {
  return (
    <Interstitial
      title="Answer questions"
      description="Every business is unique. Your answers to a handful of questions will generate setup tasks tailored to your situation."
      actions={
        <NextButton
          to={resolverUrl}
          state={{ resolverFinishUrl: location.pathname }}
        />
      }
    >
      <div className="-mb-10 max-w-[860px]">{AssessmentGraphic}</div>
    </Interstitial>
  );
};
