import { FunctionComponent } from 'react';
import { clsx } from 'clsx';
import { StopwatchIcon } from '@mosey/components/Icons';
import { RemediationsList } from './RemediationsList';
import {
  Requirement,
  RequirementComputedStatus,
  RequirementStatus,
} from '../types';
import {
  formatCompletionDateRange,
  requirementComputedStatus,
} from '../utils/format';
import {
  REQUIREMENT_STATUS_CLASSES,
  RequirementStatusMessage,
} from './requirement/RequirementStatusMessage';
import { TaskQuestionRef } from '../views/tasks-framework/utils/types';
import { QuestionTasksBanner } from './QuestionTasksBanner';
import { isSetupRequirementCategory } from '../utils/requirement';
import { RequirementComplete } from './requirement/RequirementComplete';

const RequirementStatusInProgress: FunctionComponent<{
  requirement: Requirement;
}> = ({ requirement }) => {
  if (!requirement.eta?.earliest_date || !requirement.eta?.latest_date) {
    return (
      <RequirementStatusMessage
        icon={<StopwatchIcon />}
        variant="inProgress"
        title="In progress"
        description="Your request is being processed."
      />
    );
  }
  const variant = REQUIREMENT_STATUS_CLASSES.inProgress;
  return (
    <div className="flex flex-col items-center justify-center">
      <div className={clsx('size-10 rounded-full p-1 text-white', variant.bg)}>
        <StopwatchIcon />
      </div>
      <h3 className={clsx('text-2xl font-bold', variant.text)}>In progress</h3>
      <div className="mt-4 max-w-md text-center">
        <p className="text-gray-500">Estimated completion</p>
        <p className="text-lg font-semibold text-gray-900">
          {formatCompletionDateRange(
            requirement.eta.earliest_date,
            requirement.eta.latest_date,
          )}
        </p>
      </div>
    </div>
  );
};

type ManagedRequirementProgressProps = {
  requirement: Requirement;
};

const ManagedRequirementProgress: FunctionComponent<
  ManagedRequirementProgressProps
> = ({ requirement }) => {
  if (requirement.status === RequirementStatus.Done) {
    return <RequirementComplete requirement={requirement} />;
  }

  if (requirement.status === RequirementStatus.InProgress) {
    return <RequirementStatusInProgress requirement={requirement} />;
  }

  return (
    <div className="flex h-64 items-center justify-center">
      <div className="flex items-center italic text-gray-500">
        Something went wrong, we&apos;re looking into it.
      </div>
    </div>
  );
};

// Shows the status of the requirement or steps to complete.
type RequirementProgressProps = {
  requirement: Requirement;
  questionTasks: TaskQuestionRef[];
  // Whether the requirement is managed by a provider
  isManaged: boolean;
  // Whether the requirement is managed by us
  isManagedByMosey: boolean;
  // Callback for whenever the requirement updates
  onRequirementChange: () => void;
};

export const RequirementProgressNew: FunctionComponent<
  RequirementProgressProps
> = ({
  requirement,
  questionTasks,
  isManaged,
  isManagedByMosey,
  onRequirementChange,
}) => {
  const inactiveStatuses = [
    RequirementComputedStatus.Locked,
    RequirementComputedStatus.Deferred,
    RequirementComputedStatus.Managed,
  ];
  const computedStatus = requirementComputedStatus(requirement);
  const isSetupRequirement = isSetupRequirementCategory(requirement);
  const regionCode = requirement.region[0];
  const isDisabled =
    requirement.is_virtual ||
    inactiveStatuses.includes(computedStatus) ||
    isManaged ||
    isManagedByMosey ||
    requirement.is_blocked;

  if (isManagedByMosey) {
    return (
      <div className="flex grow flex-col justify-center py-4">
        <ManagedRequirementProgress requirement={requirement} />
      </div>
    );
  }

  return (
    <>
      {questionTasks && questionTasks.length > 0 ? (
        <QuestionTasksBanner
          regionCode={regionCode}
          show
          isNested
          isSetup={isSetupRequirement}
        />
      ) : (
        <RemediationsList
          requirement={requirement}
          onChange={onRequirementChange}
          remediations={requirement.remediations}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};
