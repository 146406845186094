import { RequirementStatusMessage } from './RequirementStatusMessage';
import { CheckIcon } from '@heroicons/react/solid';
import { Requirement } from '../../types';
import { HandbookRequirementComplete } from './HandbookRequirementComplete';
import { isHandbookRequirement } from '../../views/handbook/utils';

type RequirementCompleteProps = {
  requirement: Requirement;
};

export const RequirementComplete = ({
  requirement,
}: RequirementCompleteProps) => {
  if (requirement.is_managed && isHandbookRequirement(requirement)) {
    return <HandbookRequirementComplete requirement={requirement} />;
  }

  return (
    <RequirementStatusMessage
      icon={<CheckIcon />}
      title="All done!"
      description={`${requirement.title} is complete.`}
    />
  );
};
