import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { getDecodedAccessToken } from '@mosey/utils/auth';
import { NavItem, NavIcon } from '@mosey/components/navigation/NavItem';
import { SubNavItem } from '@mosey/components/navigation/SubNavItem';
import { Search } from './Search';
import {
  OfficeIcon,
  CollapseLeftIcon,
  ExpandRightIcon,
  SupportIcon,
  UserIcon,
  CheckIcon,
} from '@mosey/components/Icons';
import { IconButton } from '@mosey/components/buttons/IconButton';
import * as config from '../../settings/config';
import {
  switchLegalEntity,
  switchLegalEntityForImpersonator,
} from '../../utils/auth';
import { getImpersonatedUser } from '../../utils/impersonation';
import logo from '../../assets/logo.svg';
import logomark from '../../assets/logomark.svg';
import { useUser } from '../../hooks/useUser';
import { ImpersonationAlert } from './ImpersonationAlert';
import { useTasks } from '../../views/tasks-framework/utils/hooks';

type AppNavProps = {
  children?: React.ReactNode;
};

const AppNav: FunctionComponent<AppNavProps> = ({ children }) => {
  const user = useUser();
  const isHome = useMatch('/home');
  const isLocationResolver = Boolean(
    useMatch('locations/:countryId/:locationId/resolver/:resolverType?/*'),
  );
  const isGenericResolver = Boolean(useMatch('resolver/:resolverType?/*'));
  const tasks = useTasks();
  const [isNavClosed, setIsNavClosed] = useState(!isHome);
  const navigate = useNavigate();
  const {
    legal_entities,
    legal_entity: {
      business_profile_submitted: isBusinessProfileSubmitted,
      is_handbook_enabled: isHandbookEnabled,
    },
  } = user;

  const decodedAccessToken = getDecodedAccessToken();
  const impersonatedUser = getImpersonatedUser(decodedAccessToken);

  const profileMenuRef = useRef<HTMLDivElement>(null);
  const legalEntitySwitcherRef = useRef<HTMLDivElement>(null);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showLegalEntities, setShowLegalEntities] = useState<boolean>(false);

  useEffect(() => {
    setIsNavClosed(
      !isHome ||
        ((isLocationResolver || isGenericResolver) && tasks.length > 1),
    );
  }, [isHome, isLocationResolver, isGenericResolver, tasks.length]);

  // Exit menu if clicking outside the user profile menu
  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        showMenu &&
        profileMenuRef.current &&
        e.target instanceof Node &&
        !profileMenuRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMenu]);

  // Exit menu if clicking outside the legal entities switcher
  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        showLegalEntities &&
        e.target instanceof Node &&
        legalEntitySwitcherRef.current &&
        !legalEntitySwitcherRef.current.contains(e.target)
      ) {
        setShowLegalEntities(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showLegalEntities]);

  const handleMenuClick = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const isNavDisabled = !isBusinessProfileSubmitted;
  const navItemDisabledMessage =
    'Finish setting up your business profile to unlock.';

  const handleShowLegalEntities = () => {
    setShowLegalEntities((current) => !current);
  };

  const handleSwitchToLegalEntity = async (e: React.MouseEvent) => {
    e.preventDefault();
    const legalEntityId = e.currentTarget.getAttribute('data-id');
    const legalEntity = legal_entities.find((i) => i.id === legalEntityId);

    if (!legalEntityId || !legalEntity) {
      return;
    }
    // If its an impersonator, they won't have a refresh token in cookie, so we get a new access token same way
    if (impersonatedUser) {
      await switchLegalEntityForImpersonator(legalEntityId);
    } else {
      await switchLegalEntity(legalEntityId);
    }

    setShowLegalEntities(false);

    // Force reload the router
    navigate(0);
  };

  return (
    <div className="flex min-h-screen">
      <aside
        className={clsx(
          'relative hidden h-screen border-r bg-white transition-nav duration-300 ease-in-out sm:block',
          {
            'w-16': isNavClosed,
            'w-72': !isNavClosed,
          },
        )}
      >
        <nav>
          <div
            className={clsx(
              'flex h-16 items-center border-b border-gray-200 transition-nav duration-300',
              {
                'px-5': isNavClosed,
                'px-7': !isNavClosed,
              },
            )}
          >
            <div
              className={clsx(
                'flex shrink-0 transition-nav duration-300 ease-in-out',
                {
                  'w-6 opacity-1': isNavClosed,
                  'opacity-0 w-0': !isNavClosed,
                },
              )}
            >
              <img className="mx-auto size-6" src={logomark} alt="Logo" />
            </div>

            <div
              className={clsx(
                'overflow-hidden transition-nav duration-300 ease-in-out',
                {
                  'opacity-0 w-0 px-0': isNavClosed,
                  'w-20': !isNavClosed,
                },
              )}
            >
              <img className="mt-1" src={logo} alt="Logo" />
            </div>
          </div>
          <div className="mb-2 mt-8 flex max-h-10 cursor-pointer pl-4">
            <div
              className="flex flex-1 py-2 text-gray-700"
              onClick={handleShowLegalEntities}
            >
              <div
                className={clsx(
                  'flex justify-center overflow-hidden font-semibold transition-nav duration-300',
                  {
                    'pl-1': isNavClosed,
                    'pl-3': !isNavClosed,
                  },
                )}
              >
                <div className="flex w-6 justify-center">
                  <OfficeIcon className="size-6 shrink-0 text-gray-400" />
                </div>
                <div
                  className={clsx(
                    'ml-2 truncate transition-nav duration-300 ease-in-out',
                    {
                      'opacity-0 w-0': isNavClosed,
                      'w-[155px]': !isNavClosed,
                    },
                  )}
                >
                  {showLegalEntities && legal_entities.length > 1 ? (
                    <div
                      className="absolute z-10 -ml-11 -mt-2 min-w-56 origin-top-left rounded-md bg-white shadow-lg focus:outline-none"
                      role="menu"
                      ref={legalEntitySwitcherRef}
                    >
                      <div className="py-1" role="none">
                        {legal_entities.map((t, idx) => (
                          <div
                            className="flex w-full cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            tabIndex={idx}
                            data-id={t.id}
                            onClick={handleSwitchToLegalEntity}
                            key={t.id}
                          >
                            <span className="flex-1">{t.name}</span>
                            {t.id === user.legal_entity.id && (
                              <span className="ml-2 rounded-xl bg-rose-700 p-1 text-white">
                                <CheckIcon className="size-3" />
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <span title={user.legal_entity.name}>
                      {user.legal_entity.name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <button
              className="z-50 float-right h-0 cursor-pointer"
              onClick={() => {
                setIsNavClosed(!isNavClosed);
              }}
              aria-label={`${isNavClosed ? 'Open' : 'Close'} navigation menu`}
            >
              {isNavClosed ? (
                <ExpandRightIcon className="ml-0.5 size-5 rounded-2xl border-2 border-gray-200 bg-white text-rose-500" />
              ) : (
                <CollapseLeftIcon className="-mr-2.5 size-5 rounded-2xl border-2 border-gray-200 bg-white text-rose-700" />
              )}
            </button>
          </div>
          <div className="mx-6 mb-4 h-px py-2">
            <div className="border-t border-gray-100" />
          </div>
          <div>
            <div
              className={clsx(
                'flex flex-col font-normal text-gray-600 transition-nav duration-300',
                {
                  'px-5 ml-0.5': isNavClosed,
                  'px-7': !isNavClosed,
                },
              )}
            >
              <NavItem
                name="Home"
                to="/home"
                icon={NavIcon.Home}
                isClosed={isNavClosed}
                isDisabled={isNavDisabled}
                disabledTooltipMessage={navItemDisabledMessage}
              />
              <NavItem
                name="Locations"
                to="/locations/usa"
                icon={NavIcon.Locations}
                isClosed={isNavClosed}
                isDisabled={isNavDisabled}
                disabledTooltipMessage={navItemDisabledMessage}
              />
              <NavItem
                name="Tasks"
                to="/tasks"
                icon={NavIcon.Tasks}
                isClosed={isNavClosed}
                isDisabled={isNavDisabled}
                disabledTooltipMessage={navItemDisabledMessage}
              >
                <SubNavItem name="Assigned to me" to="/tasks/me" />
                <SubNavItem name="All" to="/tasks/all" />
              </NavItem>
              <NavItem
                name="Calendar"
                to="/calendar"
                icon={NavIcon.Calendar}
                isClosed={isNavClosed}
                isDisabled={isNavDisabled}
                disabledTooltipMessage={navItemDisabledMessage}
              />
              <NavItem
                name="Legislation"
                to="/legislation"
                icon={NavIcon.UpcomingLegislation}
                isClosed={isNavClosed}
                isDisabled={isNavDisabled}
                disabledTooltipMessage={navItemDisabledMessage}
              />
              <NavItem
                name="Mailroom"
                to="/mail"
                icon={NavIcon.MailRoom}
                isClosed={isNavClosed}
                isDisabled={isNavDisabled}
                disabledTooltipMessage={navItemDisabledMessage}
              />
              {isHandbookEnabled && (
                <NavItem
                  name="Handbook"
                  to="/handbook"
                  icon={NavIcon.Handbook}
                  isClosed={isNavClosed}
                  isDisabled={isNavDisabled}
                  disabledTooltipMessage={navItemDisabledMessage}
                />
              )}

              <div className="mx-6 mb-4 mt-2 h-px py-2">
                <div className="border-t border-gray-100" />
              </div>
              <NavItem
                name="Settings"
                to="/settings"
                icon={NavIcon.Settings}
                isClosed={isNavClosed}
                isDisabled={isNavDisabled}
                disabledTooltipMessage={navItemDisabledMessage}
              />
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.mosey.com"
              className={clsx(
                'absolute bottom-0 flex h-12 w-full items-center border-t bg-gray-100 text-xs font-normal text-gray-500 transition-nav duration-300',
                {
                  'px-5': isNavClosed,
                  'pl-8': !isNavClosed,
                },
              )}
            >
              <SupportIcon className="mx-auto size-5 shrink-0" />

              <div
                className={clsx(
                  'static h-4 overflow-hidden transition-nav duration-300 ease-in-out',
                  {
                    'opacity-0 w-0': isNavClosed,
                    'w-72 ml-2': !isNavClosed,
                  },
                )}
              >
                Help »
              </div>
            </a>
          </div>
        </nav>
      </aside>
      <div className="flex h-screen w-full flex-col overflow-y-hidden bg-white">
        <header className="hidden h-16 w-full border-b border-gray-200 bg-white py-2 sm:flex sm:items-center">
          <div className="flex-1">
            <div className="mx-auto w-96">{!isNavDisabled && <Search />}</div>
          </div>
          <div className="relative flex justify-end px-6 py-2">
            <IconButton
              type="button"
              aria-label="Open user menu"
              id="navMenu"
              size="small"
              onClick={handleMenuClick}
              isFullRounded
              className="relative"
              variant="secondary"
              icon={<UserIcon className="size-5" />}
            />
            {showMenu && (
              <div
                ref={profileMenuRef}
                className="absolute z-50 mt-16 w-32 rounded-lg bg-white py-2 shadow-lg"
              >
                {user.role === 'admin' && (
                  <a
                    href={config.ADMIN_BASE_URL}
                    className="block px-4 py-2 hover:bg-gray-100"
                  >
                    Admin
                  </a>
                )}
                <Link
                  to="/logout"
                  className="block px-4 py-2 hover:bg-gray-300"
                >
                  Sign Out
                </Link>
              </div>
            )}
          </div>
        </header>

        <ImpersonationAlert />

        <main className="flex-1 overflow-auto">{children}</main>
      </div>
    </div>
  );
};

export default AppNav;
