import { FunctionComponent } from 'react';
import { NOTIFICATION_TOPIC_CODE_TO_ICON } from './types';
import { IconProps } from '@mosey/components/Icons';
import { Toggle } from '@mosey/components/toggles/Toggle';
import { NotificationTopicEnum } from '@mosey/api-types';
import { Description, Field, Label } from '@headlessui/react';
import { useFetcher } from 'react-router-dom';

export type NotificationTopicSettingProps = {
  title: string;
  description: string;
  code: NotificationTopicEnum;
  enabled: boolean;
};

const NotificationTopicSetting: FunctionComponent<
  NotificationTopicSettingProps
> = ({ title, description, code, enabled }) => {
  const Icon: FunctionComponent<IconProps> =
    NOTIFICATION_TOPIC_CODE_TO_ICON[code];

  const fetcher = useFetcher();
  const onChange = (isChecked: boolean) => {
    fetcher.submit(
      {
        topic_code: code,
        topic_title: title,
        enabled: isChecked,
      },
      { method: 'POST', encType: 'application/json' },
    );
  };

  return (
    <div className="flex border-1 border-gray-200 mb-2 p-4 rounded-sm h-fit items-start relative">
      <div className="flex justify-center items-center bg-sage-500 rounded-full size-8 min-w-8 mr-3">
        <Icon className="flex size-4" />
      </div>
      <Field>
        <Label className="text-base font-semibold text-zinc-800">{title}</Label>
        <Description className="text-sm font-normal text-zinc-600">
          {description}
        </Description>
        <div className="absolute top-4 right-4">
          <Toggle checked={enabled} onChange={onChange} />
        </div>
      </Field>
    </div>
  );
};

export default NotificationTopicSetting;
